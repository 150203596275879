<template>
  <div>
    <b-modal
      :active="activate"
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      :width="550"
      @close="$emit('close')"
    >
      <form id="subscription-form" @submit.prevent="savePayment">
        <div class="modal-card" style="width: 550px">
          <header class="modal-card-head text-center">
            <div class="w-full text-center">
              <div class="text-lg font-bold">Upgrade to PRO</div>
              <p class="text-sm">{{ websiteUrl }}</p>
            </div>
          </header>
          <section class="modal-card-body">
            <div class="flex justify-between mb-6">
              <plan-card
                id="monthly-plan"
                title="Monthly"
                :total="monthlyPlan.total"
                billed="monthly"
                class="w-1/2 mr-4 md:mr-4"
                :selected="selectedPlan.priceId === monthlyPlan.priceId"
                @selected="selectPlan('month')"
              />
              <plan-card
                id="yearly_plan"
                title="Yearly"
                :total="yearlyPlan.total"
                :original-price="yearlyPlan.original"
                billed="yearly"
                class="w-1/2"
                :selected="selectedPlan.priceId === yearlyPlan.priceId"
                @selected="selectPlan('year')"
              />
            </div>
            <div>
              <div
                class="uppercase tracking-wide text-gray-700 text-sm font-bold"
              >
                Includes
              </div>
              <p>
                Connect Custom Domain, SSL certificate, Hosting, Embeddable
                Widget, WhatsApp Checkout, Remove Biew Branding and Integrations
              </p>
            </div>
            <div class="border-t my-2"></div>
            <div class="form-row">
              <div class="uppercase tracking-wide text-gray-700 font-bold">
                Total: {{ totalFormatted }}
              </div>
              <div class="flex flex-wrap -mx-3 mb-2 mt-2">
                <div class="w-full px-3 md:mb-0">
                  <label
                    class="uppercase tracking-wide text-gray-700 text-xs font-bold"
                    for="full-name"
                  >
                    Full name
                  </label>
                  <input
                    id="full-name"
                    class="appearance-none block w-full bg-gray-200 border rounded-md py-3 px-2 mt-2 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                    placeholder="First and last name"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-wrap -mx-3">
              <div class="w-full px-3 mb-0">
                <label
                  class="uppercase tracking-wide text-gray-700 text-xs font-bold"
                  for="first-name"
                >
                  Card
                </label>
                <div
                  id="card-element"
                  ref="card"
                  class="appearance-none w-full bg-gray-200 text-gray-700 border rounded-md py-3 px-2 mt-2 leading-tight focus:outline-none focus:bg-white"
                ></div>
                <div
                  id="card-element-errors"
                  class="text-red-600 text-base mt-2"
                  role="alert"
                ></div>
              </div>
            </div>
            <button
              id="submit-premium"
              class="w-full bg-gray-900 hover:bg-white hover:shadow-outline hover:text-black border hover:border-black focus:shadow-outline text-white focus:bg-white focus:text-black py-3 px-4 mt-4 rounded-md shadow"
              type="submit"
            >
              Subscribe
            </button>
          </section>
        </div>
      </form>
      <b-loading
        :is-full-page="false"
        :active.sync="isProcessingPayment"
        :can-cancel="false"
      ></b-loading>
    </b-modal>
  </div>
</template>

<script>
import ApiPayments from "@/services/payments";
import ApiSites from "@/services/sites";
import * as Sentry from "@sentry/browser";
import PlanCard from "./PlanCard";

export default {
  name: "PaymentModal",

  components: {
    PlanCard
  },

  props: {
    activate: {
      type: Boolean,
      default: false
    },
    websiteId: {
      type: String,
      required: true
    },
    websiteUrl: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isProcessingPayment: false,
      mountedCard: false,
      planType: "month",
      card: null
    };
  },

  computed: {
    countryCode() {
      return this.$store.state.countryCode;
    },
    isMexico() {
      return this.countryCode === "MX";
    },
    currencyCode() {
      if (this.isMexico) {
        return "mxn";
      }
      return "usd";
    },
    selectedPlan() {
      if (this.planType === "month") {
        return this.monthlyPlan;
      }
      return this.yearlyPlan;
    },
    monthlyPlan() {
      if (this.isMexico) {
        return {
          total: 16000,
          original: 20000,
          priceId: process.env.VUE_APP_STRIPE_MONTHLY_PLAN_PRICE_ID_MX
        };
      }
      return {
        total: 800,
        original: 1000,
        priceId: process.env.VUE_APP_STRIPE_MONTHLY_PLAN_PRICE_ID
      };
    },
    yearlyPlan() {
      if (this.isMexico) {
        return {
          total: 144000,
          original: 192000,
          priceId: process.env.VUE_APP_STRIPE_YEARLY_PLAN_PRICE_ID_MX
        };
      }
      return {
        total: 7200,
        original: 9600,
        priceId: process.env.VUE_APP_STRIPE_YEARLY_PLAN_PRICE_ID
      };
    },
    moneyFormat() {
      if (this.isMexico) {
        return new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN"
        });
      }
      return Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      });
    },
    totalFormatted() {
      return this.moneyFormat
        .format(this.selectedPlan.total / 100)
        .concat(" ", this.currencyCode);
    },
    userId() {
      return this.$store.state.user.uid;
    },
    stripeCustomerId() {
      return this.$store.state.user.stripeCustomerId;
    }
  },
  updated() {
    if (this.$refs.card && !this.mountedCard) {
      this.mountCard();
    }
  },
  methods: {
    selectPlan(planName) {
      this.planType = planName;
    },
    mountCard() {
      const style = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };
      const elements = this.$stripe.elements();
      this.card = elements.create("card", { style: style });
      this.card.mount(this.$refs.card);
      this.card.on("change", event => {
        this.showCardError(event);
      });
      this.mountedCard = true;
    },

    showCardError(event) {
      this.isProcessingPayment = false;
      let displayError = document.getElementById("card-element-errors");
      // display request error if exsts
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    },

    savePayment() {
      this.isProcessingPayment = true;
      // If a previous payment was attempted, get the latest invoice
      const latestInvoicePaymentIntentStatus = localStorage.getItem(
        "latestInvoicePaymentIntentStatus"
      );

      if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
        const invoiceId = localStorage.getItem("latestInvoiceId");
        const isPaymentRetry = true;
        // create new payment method & retry payment on invoice with new payment method
        this.createPaymentMethod({
          card: this.card,
          isPaymentRetry,
          invoiceId
        });
      } else {
        // create new payment method & create subscription
        this.createPaymentMethod({ card: this.card });
      }
    },
    createPaymentMethod(args) {
      const { card, isPaymentRetry, invoiceId } = args;
      // Set up payment method for recurring usage
      const customerId = this.stripeCustomerId;
      const priceId = this.selectedPlan.priceId;
      const billingName = document.querySelector("#full-name").value;
      this.$stripe
        .createPaymentMethod({
          type: "card",
          card: card,
          allow_redisplay: "always",
          billing_details: {
            name: billingName
          }
        })
        .then(result => {
          if (result.error) {
            this.displayError(result);
          } else {
            if (isPaymentRetry) {
              // Update the payment method and retry invoice payment
              this.retryInvoiceWithNewPaymentMethod({
                customerId: customerId,
                paymentMethodId: result.paymentMethod.id,
                invoiceId: invoiceId,
                priceId: priceId
              });
            } else {
              // Create the subscription
              this.createSubscription({
                customerId: customerId,
                paymentMethodId: result.paymentMethod.id,
                priceId: priceId
              });
            }
          }
        });
    },

    createSubscription(args) {
      const { customerId, paymentMethodId, priceId } = args;
      return (
        this.$http
          .post("/api/payments/create-subscription", {
            customerId: customerId,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            websiteId: this.websiteId,
            uid: this.userId
          })
          .then(response => {
            return response.data;
          })
          // If the card is declined, display an error to the user.
          .then(result => {
            if (result.error) {
              // The card had an error when trying to attach it to a customer.
              throw result;
            }
            return result;
          })
          // Normalize the result to contain the object returned by Stripe.
          // Add the additional details we need.
          .then(result => {
            return {
              paymentMethodId: paymentMethodId,
              priceId: priceId,
              subscription: result
            };
          })
          // Some payment methods require a customer to be on session
          // to complete the payment process. Check the status of the
          // payment intent to handle these actions.
          .then(this.handlePaymentThatRequiresCustomerAction)
          // If attaching this card to a Customer object succeeds,
          // but attempts to charge the customer fail, you
          // get a requires_payment_method error.
          .then(this.handleRequiresPaymentMethod)
          // No more actions required. Provision your service for the user.
          .then(this.onSubscriptionComplete)
          .catch(error => {
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.

            this.isProcessingPayment = false;
            if (error?.response?.data?.error?.message) {
              this.$buefy.notification.open({
                message: this.$t("error_messages.create_payment_session"),
                type: "is-danger"
              });
            }
            this.showCardError(error);
          })
      );
    },

    handlePaymentThatRequiresCustomerAction(obj) {
      const {
        subscription,
        invoice, // no se utiliza
        priceId,
        paymentMethodId,
        isRetry // no se utiliza
      } = obj;
      if (subscription && subscription.status === "active") {
        // Subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId };
      }
      /**
       * TODO:
       * Revisar qué hace la siguiente parte del código porque truena en el catch
       * de promesas encadenadas.
       */
      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      let paymentIntent = invoice
        ? invoice.payment_intent
        : subscription.latest_invoice.payment_intent;
      if (
        paymentIntent.status === "requires_action" ||
        (isRetry === true && paymentIntent.status === "requires_payment_method")
      ) {
        return this.$stripe
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId
          })
          .then(result => {
            if (result.error) {
              // Start code flow to handle updating the payment details.
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc).
              throw result;
            } else {
              if (result.paymentIntent.status === "succeeded") {
                // Show a success message to your customer.
                // There's a risk of the customer closing the window before the callback.
                // We recommend setting up webhook endpoints later in this guide.
                const response = {
                  priceId: priceId,
                  subscription: subscription,
                  invoice: invoice,
                  paymentMethodId: paymentMethodId
                };
                return response;
              }
            }
          })
          .catch(error => {
            this.isProcessingPayment = false;
            this.displayError(error);
          });
      } else {
        // No customer action needed.
        return { subscription, priceId, paymentMethodId };
      }
    },

    handleRequiresPaymentMethod(obj) {
      const { subscription, paymentMethodId, priceId } = obj;
      if (subscription.status === "active") {
        // subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId };
      } else if (
        subscription.latest_invoice.payment_intent.status ===
        "requires_payment_method"
      ) {
        // Using localStorage to manage the state of the retry here,
        // feel free to replace with what you prefer.
        // Store the latest invoice ID and status.
        localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
        localStorage.setItem(
          "latestInvoicePaymentIntentStatus",
          subscription.latest_invoice.payment_intent.status
        );
        throw { error: { message: "Your card was declined." } };
      } else {
        return { subscription, priceId, paymentMethodId };
      }
    },

    async onSubscriptionComplete() {
      // Activate pro features
      try {
        this.clearCache();
        await ApiSites.upgrade({
          websiteId: this.websiteId,
          uid: this.userId
        });
        this.$buefy.notification.open({
          message: this.$t("success_messages.updated_website"),
          type: "is-success"
        });
        this.$emit("payment-success");
        ApiSites.findMyWebsites().then(res => {
          document.querySelector("#full-name").value = "";
          this.card.clear();
          this.$store.dispatch("setWebsites", res.data || []);
          this.$emit("close");
        });
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        this.isProcessingPayment = false;
      }
    },

    displayError(data) {
      if (data.error) {
        this.$buefy.notification.open({
          message: this.$t("error_messages.updated_website"),
          type: "is-danger"
        });
      }
    },

    retryInvoiceWithNewPaymentMethod(obj) {
      const { customerId, paymentMethodId, invoiceId, priceId } = obj;
      return (
        ApiPayments.retryInvoice({
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId
        })
          .then(response => {
            return response.data;
          })
          // If the card is declined, display an error to the user.
          .then(result => {
            if (result.error) {
              // The card had an error when trying to attach it to a customer.
              throw result;
            }
            return result;
          })
          // Normalize the result to contain the object returned by Stripe.
          // Add the additional details we need.
          .then(result => {
            return {
              // Use the Stripe 'object' property on the
              // returned result to understand what object is returned.
              invoice: result,
              paymentMethodId: paymentMethodId,
              priceId: priceId,
              isRetry: true
            };
          })
          // Some payment methods require a customer to be on session
          // to complete the payment process. Check the status of the
          // payment intent to handle these actions.
          .then(this.handlePaymentThatRequiresCustomerAction)
          // No more actions required. Provision your service for the user.
          .then(this.onSubscriptionComplete)
          .catch(error => {
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            this.displayError(error);
          })
      );
    },

    clearCache() {
      localStorage.removeItem("latestInvoiceId");
      localStorage.removeItem("latestInvoicePaymentIntentStatus");
    }
  }
};
</script>
